.sections {
  margin-left: 10vw;
  margin-right: 5vw;
}

section {
  margin-bottom: 5vh;
}

.section-header {
  margin-top: 0;
}

.section-p {
  font-size: 0.8em;
}

.section-img {
  width: 80vw;
  margin-top: 3vh;
}

.section-img-2 {
  width: 70vw;
}

.footer-box {
  position: relative;
  z-index: 1000;
}

@media screen and (min-width: 1024px) {
  .section-img-2{
    width: 40vw;
  }
  
  .section-p{
    font-size: 1em;
  }
}
