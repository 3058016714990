.navigator {
  position: relative;
  overflow-y: hidden;
  z-index: 1000;
}

.no-scroll {
  overflow: hidden;
}

.menu {
  width: 95vw;
  height: 93vh;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  text-decoration-thickness: 2px;
}

@keyframes fadeInUp {
  0% {
    transform: translateY(30%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

.menu a {
  color: black;
  background: none;
  border: none;
  cursor: pointer;
  height: 10vh;
  font-size: 2rem;
  padding-left: 5vw;
  padding-top: 5vh;
  text-decoration: underline;
  animation: 1s fadeInUp;
}

.more-button {
  background-color: transparent;
  border: none;
  padding: 0;
  font-weight: 400;
  color: black;
}

@media screen and (min-width: 1024px) {
  .menu {
    width: 40vw;
    height: 14vh;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: start;
    text-decoration-thickness: 2px;
  }

  .menu a {
    border: none;
    font-size: 1.5rem;
    padding-left: 2vw;
  }
}
