.heaeder {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  position: relative;
  padding-top: 5px;
  padding-bottom: 5px;
}

.logo-for-header {
  width: 23vw;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.navigator {
  flex: 1;
}

@media screen and (min-width: 1024px) {
  .heaeder {
    height: 20vh;
  }

  .logo-for-header{
    width: 18vw;
  }
}
