.gift-card-header {
  font-size: 1em;
  text-align: center;
}

.gift-card p {
  display: flex;
  align-items: center;
  padding-right: 20px;
  text-align: center;
  font-size: 15px;
}

.punch-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5vh;
}

.punch-card-header {
  font-size: 1em;
  text-align: center;
  margin-top: 2vh;
}

.punch-card-img {
  max-width: 60vw;
  border-radius: 10px;
}

.punch-card h4 {
  margin-top: 0;
  margin-bottom: 2vh;
  font-weight: 400;
}

.fade-scroll {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.fade-scroll.visible {
  opacity: 1;
  transform: translateY(0);
}

.merry-animation {
  display: block;
  z-index: 10;
}

.christmas-tree {
  width: 150px;
}

.gift-card-inside {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 1024px) {
  .extras {
    margin-top: 40vh;
  }

  .punch-card-img {
    width: 40vw;
  }

  .gift-card-inside {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
