.embla {
  width: 100%;
  overflow: hidden;
  position: relative;
  min-height: 70vh;
}

.embla__container {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.embla__slide {
  flex: 0 0 100%;
  position: relative;
  height: 90vh;
}

.header-swiper {
  margin-bottom: 0;
  margin-top: 10px;
  padding-left: 5vw;
}

.p-swiper {
  font-size: small;
  padding-left: 5vw;
  padding-right: 5vw;
  margin-top: 0;
}

.button-swiper {
  margin-left: 5vw;
  border: none;
  background: none;
  padding: 0;
  font-size: 1em;
  font-weight: 700;
  text-decoration: underline;
  color: black;
}

.swiper-photos {
  width: 100%;
  height: 65vh;
  object-fit: cover;
}


@media screen and (min-width: 768px) {
  .swiper {
    width: 80vw;
    min-height: 60vh;
    margin: 0 auto;
  }

  .swiper-container {
    height: 60vh;
  }

  .swiper-photos {
    max-width: 50vw;
    min-width: 50vw;
    min-height: 50vh;
    max-height: 70vh;
  }

  .photo-text {
    margin-top: -5vh;
    font-size: 1.2rem;
    width: 50vw;
  }

  .photo-text2 {
    margin-top: -8vh;
    font-size: 1.2rem;
    width: 50vw;
  }

  .swiper-wrapper {
    margin-left: 15vw;
  }
}

@media screen and (min-width: 1024px) {
  .swiper {
    width: 70vw;
    min-height: 50vh;
    margin: 0 auto;
  }

  .swiper-container {
    height: 50vh;
  }

  .swiper-photos {
    max-width: 35vw;
    min-width: 35vw;
    min-height: 50vh;
    max-height: 70vh;
  }

  .photo-text {
    margin-top: -4vh;
    font-size: 1.5rem;
  }

  .photo-text2 {
    margin-top: -6vh;
    font-size: 1.5rem;
  }

  .embla__slide {
    flex: 0 0 0%;
  }
}
