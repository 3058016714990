footer {
  background-color: #242424;
  color: #fff;
  padding: 20px;
}

.footer-section {
  border-bottom: 1px solid #444;
  padding: 10px 0;
}

.footer-section h3 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;
  cursor: pointer;
  margin: 0;
  font-weight: 400;
}

.footer-section h3 span {
  font-size: 1.5em;
  line-height: 0.8;
}

.footer-section ul {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.4s ease-in-out;
}

.footer-section ul.open {
  max-height: 200px;
}

.footer-section ul li {
  margin: 5px 0;
  padding-left: 15px;
  font-size: 0.9em;
  cursor: pointer;
  transition: color 0.3s ease;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.locations-href {
  text-decoration: none;
  color: #fff;
}

a {
  text-decoration: none;
  color: white;
}

.video-container {
  display: flex;
  justify-content: center;
}

.video {
  width: 20vw;
  margin-top: 1vh;
  border-radius: 50%;
}

.site-credits {
  font-size: 0.8em;
  text-decoration: underline;
  margin-top: -5px;
  display: flex;
  justify-content: end;
}

@media screen and (min-width: 1024px) {
  .video {
    width: 10vh;
  }

  .foooter-container {
    display: flex;
    justify-content: space-evenly;
  }

  .footer-section {
    padding: 0;
  }

  .footer-section h3 {
    font-size: 1em;
    width: 20vw;
  }
}
