.gallery {
  display: flex;
  width: 100vw;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 5vh;
}

.img-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gallery-img {
  max-width: 49vw;
}

.food-name {
  margin-top: -20px;
  color: white;
  font-size: x-small;
}

@media screen and (min-width: 1024px) {
  .food-name{
    font-size: 1em;
  }
}