.progress-bar {
    position: fixed;
    left: 10px;
    top: 9vh;
    height: 85vh;
    width: 2px;
    background-color: #e0e0e0;
  }
  
  .progress-dot {
    position: absolute;
    left: -5px;
    width: 12px;
    height: 12px;
    background-color: #c0c0c0;
    border-radius: 50%;
    transition: background-color 0.3s ease, top 0.3s ease;
  }
  
  .progress-dot.active {
    background-color: #000;
  }
  