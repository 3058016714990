.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 1;
  transition: opacity 1s ease, background-color 1s ease;
}

.logo-loading {
  width: 50vw;
  z-index: 1100;
  position: relative;
}

.yourcozycorner {
  margin-top: -5vh;
  margin-bottom: 3vh;
}

.loader {
  display: inline-grid;
  padding: 5px;
  background: #fff;
  filter: blur(4px) contrast(12);
  margin-left: 5vw;
}
.loader:before {
  content: "";
  height: 40px;
  aspect-ratio: 3;
  --c: #0000 64%, #000 66% 98%, #0000 101%;
  background: radial-gradient(35% 146% at 50% 159%, var(--c)) 0 0,
    radial-gradient(35% 146% at 50% -59%, var(--c)) 100% 100%;
  background-size: calc(200% / 3) 50%;
  background-repeat: repeat-x;
  mask: repeating-linear-gradient(90deg, #000 0 10%, #0000 0 20%);
  animation: l12 0.8s infinite linear;
}
@keyframes l12 {
  to {
    background-position: -200% 0, -100% 100%;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.logo-loading,
.yourcozycorner,
.loader {
  animation: fadeOut 2s ease-in; /* 1.8s fade-out animation */
}
