ul.react-tabs__tab-list {
  display: flex;
  justify-content: space-evenly;
  list-style: none;
  font-weight: 700;
  cursor: pointer;
  margin: 0;
  padding-left: 10px;
  padding-right: 10px;
}

ul.react-tabs__tab-list p {
  display: flex;
  justify-content: center;
  color: black;
  margin-bottom: 0;
  cursor: pointer;
}

.react-tabs__tab--selected {
  text-decoration: underline;
  color: black;
}

.menu-h2 {
  text-align: center;
  padding: 30px 0 8px;
  position: relative;
  margin-top: 0;
}

.menu-h2::before {
  content: "";
  position: absolute;
  left: 38%;
  bottom: 16px;
  width: 90px;
  height: 10px;
  transform: skew(-12deg);
  background: rgba(238, 111, 87, 0.5);
  z-index: -1;
}

.food-box {
  display: flex;
  margin-left: 7vw;
  width: 86vw;
  justify-content: space-between;
  border-bottom: 1px dotted rgba(32, 32, 32, 0.522);
  border-bottom-width: 1.5px;
}

.section {
  font-size: 15px;
  margin-bottom: 0;
  padding-left: 7vw;
  text-decoration: underline;
}

.section-description {
  font-size: smaller;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 7vw;
}

.item-name,
.price {
  font-size: small;
}

@media screen and (min-width: 1024px) {
  .menu-h2::before {
    left: 40%;
    width: 20vw;
  }
}
